import "theme/global.css"
import "./BlogPostTemplate.css"

// import "prismjs/themes/prism-tomorrow.css"

import React from "react"

import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"

// import { rhythm, scale } from "theme/typography"

import {
  Header,
  LayoutContainer,
  Footer,
  Divider,
  SEO,
  MDXComponents,
  BrowseLinksSection,
} from "components"

import GlobalStyle from "theme/GlobalStyle"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx
    const { previous, next } = this.props.pageContext

    return (
      <>
        <GlobalStyle />
        <Header />
        <main>
          <SEO
            title={post.frontmatter.title}
            description={post.frontmatter.description || post.excerpt}
          />
          <LayoutContainer hasPaddingTop={false} mb={5} mt={5}>
            <h1>{post.frontmatter.title}</h1>
            <p style={{ opacity: 0.75 }}>{post.frontmatter.subtitle}</p>
            <p
              style={{
                // ...scale(-1 / 5),
                display: `block`,
                // marginBottom: rhythm(1),
                // marginTop: rhythm(-1),
                opacity: 0.5,
              }}
            >
              {post.frontmatter.date}
            </p>
          </LayoutContainer>
          <Divider mb={4} />

          <LayoutContainer
            hasPaddingTop={false}
            maxWidth={"680px"}
            mb={5}
            mt={5}
          >
            <MDXProvider components={MDXComponents}>
              <MDXRenderer>{post.body}</MDXRenderer>
            </MDXProvider>
          </LayoutContainer>
        </main>
        <BrowseLinksSection previous={previous} next={next} />
        <Footer />
      </>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query ProjectPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      tableOfContents
      frontmatter {
        title
        subtitle
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
